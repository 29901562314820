import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/DocsLayout.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Alert = makeShortcode("Alert");
const Code = makeShortcode("Code");
const AlertTitle = makeShortcode("AlertTitle");
const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`When the `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/ca/init"
      }}><inlineCode parentName="a">{`step ca init`}</inlineCode></a>{` command sets up your PKI, it creates an initial
configuration file for your CA and stores it in `}<inlineCode parentName="p">{`$(step path)/config/ca.json`}</inlineCode>{`.`}</p>
    <p>{`In this document we'll describe how to configure every aspect of `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{`. You'll
learn how to configure your CA to:`}</p>
    <ul>
      <li parentName="ul">{`bind to a non default address`}</li>
      <li parentName="ul">{`generate ACME certificates`}</li>
      <li parentName="ul">{`adjust the default lifetime of certificates`}</li>
      <li parentName="ul">{`store certificates in memory, on the file system, or in a database`}</li>
      <li parentName="ul">{`set the root and intermediate PKI chain that will be used to sign new certificates`}</li>
      <li parentName="ul">{`and much more`}</li>
    </ul>
    <h2>{`Overview`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#specifying-a-configuration-file"
        }}>{`Specifying a Configuration File`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#environment-variables"
        }}>{`Environment Variables`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#basic-configuration-options"
        }}>{`Basic Configuration Options`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#provisioners"
        }}>{`Provisioners`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#policy"
        }}>{`Certificate Issuance Policies`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#templates"
        }}>{`Certificate Templates`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#databases"
        }}>{`Databases`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#badger"
            }}>{`Badger`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#boltdb"
            }}>{`BoltDB`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#mysql"
            }}>{`MySQL`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#postgresql"
            }}>{`PostgreSQL`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#schema"
            }}>{`Schema`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#cryptographic-protection"
        }}>{`Cryptographic Protection`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#google-cloud-kms"
            }}>{`Google Cloud KMS`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#aws-kms"
            }}>{`AWS KMS`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#azure-key-vault"
            }}>{`Azure Key Vault`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#pkcs-11"
            }}>{`PKCS #11`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#yubikey-piv"
            }}>{`YubiKey PIV`}</a></li>
        </ul>
      </li>
    </ul>
    <h2>{`Specifying a Configuration File`}</h2>
    <p>{`When you run `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{`, you must provide a path to a configuration file
(`}<inlineCode parentName="p">{`ca.json`}</inlineCode>{`).
It is the only required argument.
Typically you'd use `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/path"
      }}><inlineCode parentName="a">{`step path`}</inlineCode></a>{` to discover the path to `}<inlineCode parentName="p">{`ca.json`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session"
      }}>{`$ step-ca $(step path)/config/ca.json
`}</code></pre>
    <p>{`The output of `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/path"
      }}><inlineCode parentName="a">{`step path`}</inlineCode></a>{` will vary based on the `}<inlineCode parentName="p">{`$STEPPATH`}</inlineCode>{` environment variable, and whether you `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-cli/the-step-command#contexts-working-with-multiple-cas"
      }}>{`use the `}<inlineCode parentName="a">{`step`}</inlineCode>{` command with multiple CAs`}</a>{`.`}</p>
    <p>{`For non-interactive use (eg. as a `}<inlineCode parentName="p">{`systemd`}</inlineCode>{` service), you can also add a
`}<inlineCode parentName="p">{`--password-file`}</inlineCode>{` flag with the name of a file containing the password for the
CA's keys.`}</p>
    <h2>{`Environment Variables`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`STEPDEBUG`}</inlineCode>{` When set to `}<inlineCode parentName="li">{`1`}</inlineCode>{`, `}<inlineCode parentName="li">{`step-ca`}</inlineCode>{` will provide extra diagnostic information for debugging. This variable can also be used with `}<inlineCode parentName="li">{`step`}</inlineCode>{`.`}</li>
    </ul>
    <h2>{`Basic Configuration Options`}</h2>
    <h3>{`Example Configuration`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "root": "examples/pki/secrets/root_ca.crt",
  "federatedRoots": "examples/pki/secrets/federated_root_ca.crt",
  "crt": "examples/pki/secrets/intermediate_ca.crt",
  "key": "examples/pki/secrets/intermediate_ca_key",
  "address": ":9000",
  "dnsNames": [
    "localhost"
  ],
  "logger": {
    "format": "text"
  },
  "ssh": {
      "hostKey": "/examples/pki/secrets/secrets.host.key",
      "userKey": "/examples/pki/secrets/secrets.user.key"
  },
  "db": {
    "type": "badger",
    "dataSource": "./.step/db"
  },
  "crl": {
    "enabled": false
  }, 
  "authority": {
    "claims": {
      "minTLSCertDuration": "5m",
      "maxTLSCertDuration": "24h",
      "defaultTLSCertDuration": "24h",
      "disableRenewal": false,
      "allowRenewalAfterExpiry": false,
      "minHostSSHCertDuration": "5m",
      "maxHostSSHCertDuration": "1680h",
      "defaultHostSSHCertDuration": "720h",
      "minUserSSHCertDuration": "5m",
      "maxUserSSHCertDuration": "24h",
      "defaultUserSSHCertDuration": "16h"
    },
    "policy": {
      "x509": {
        "allow": {
          "dns": ["*.local"]
        }
      },
      "ssh": {
        "user": {
          "allow": {
            "email": ["@local"]
          }
        },
        "host": {
          "allow": {
            "dns": ["*.local"]
          }
        }
      }
    },
    "provisioners": [
        {
            "type": "jwk",
            "name": "mike@smallstep.com",
            "key": {
                "use": "sig",
                "kty": "EC",
                "kid": "YYNxZ0rq0WsT2MlqLCWvgme3jszkmt99KjoGEJJwAKs",
                "crv": "P-256",
                "alg": "ES256",
                "x": "LsI8nHBflc-mrCbRqhl8d3hSl5sYuSM1AbXBmRfznyg",
                "y": "F99LoOvi7z-ZkumsgoHIhodP8q9brXe4bhF3szK-c_w"
            },
            "encryptedKey": "eyJhbGciOiJQQkVTMi1IUzI1NitBMTI4S1ciLCJjdHkiOiJqd2sranNvbiIsImVuYyI6IkEyNTZHQ00iLCJwMmMiOjEwMDAwMCwicDJzIjoiVERQS2dzcEItTUR4ZDJxTGo0VlpwdyJ9.2_j0cZgTm2eFkZ-hrtr1hBIvLxN0w3TZhbX0Jrrq7vBMaywhgFcGTA.mCasZCbZJ-JT7vjA.bW052WDKSf_ueEXq1dyxLq0n3qXWRO-LXr7OzBLdUKWKSBGQrzqS5KJWqdUCPoMIHTqpwYvm-iD6uFlcxKBYxnsAG_hoq_V3icvvwNQQSd_q7Thxr2_KtPIDJWNuX1t5qXp11hkgb-8d5HO93CmN7xNDG89pzSUepT6RYXOZ483mP5fre9qzkfnrjx3oPROCnf3SnIVUvqk7fwfXuniNsg3NrNqncHYUQNReiq3e9I1R60w0ZQTvIReY7-zfiq7iPgVqmu5I7XGgFK4iBv0L7UOEora65b4hRWeLxg5t7OCfUqrS9yxAk8FdjFb9sEfjopWViPRepB0dYPH8dVI.fb6-7XWqp0j6CR9Li0NI-Q",
            "claims": {
                "minTLSCertDuration": "1m0s",
                "defaultTLSCertDuration": "2m0s"
            },
        "options": {
          "x509": {
            "templateFile": "templates/certs/x509/default.tpl"
          },
          "ssh": {
            "templateFile": "templates/certs/ssh/default.tpl"
          }
        }
        },
      {
        "type": "OIDC",
        "name": "Google",
        "clientID": "1087160488420-8qt7bavg3qesdhs6it824mhnfgcfe8il.apps.googleusercontent.com",
        "clientSecret": "udTrOT3gzrO7W9fDPgZQLfYJ",
        "configurationEndpoint": "https://accounts.google.com/.well-known/openid-configuration",
        "admins": ["you@smallstep.com"],
        "domains": ["smallstep.com"],
        "listenAddress": ":10000",
        "claims": {
          "maxTLSCertDuration": "8h",
          "defaultTLSCertDuration": "2h",
          "enableSSHCA": true,
          "disableRenewal": true
        },
        "options": {
          "x509": {
            "templateFile": "templates/certs/x509/default.tpl"
          },
          "ssh": {
            "templateFile": "templates/certs/ssh/default.tpl"
          }
        }
      },
      {
            "type": "SSHPOP",
            "name": "sshpop-smallstep",
            "claims": {
                "enableSSHCA": true
            }
        },
      {
            "type": "ACME",
            "name": "my-acme-provisioner"
        }
    ]
  },
  "tls": {
    "cipherSuites": [
        "TLS_ECDHE_ECDSA_WITH_CHACHA20_POLY1305",
        "TLS_ECDHE_ECDSA_WITH_AES_128_GCM_SHA256"
    ],
    "minVersion": 1.2,
    "maxVersion": 1.2,
    "renegotiation": false
  },
  "password": "p@55w0rd"
}
`}</code></pre>
    <h3>{`Configuration Options`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`root`}</strong>{`: location of the root certificate on the filesystem. The root certificate is used to mutually authenticate all API clients of the CA.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`federatedRoots`}</strong>{`: location of the federated root certificates on the filesystem. The federated roots are used to authenticate client and servers using TLS certificates from multiple CAs.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`crt`}</strong>{`: location of the intermediate certificate on the filesystem. The intermediate certificate is returned alongside each new certificate, allowing the client to complete the certificate chain.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`key`}</strong>{`: location of the intermediate private key on the filesystem. The intermediate key signs all new certificates generated by the CA.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`kms`}</strong>{`: enables and configures `}<a parentName="p" {...{
            "href": "#cryptographic-protection"
          }}>{`cryptographic protection`}</a>{` using cloud key management services or hardware security modules.`}</p>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li"><strong parentName="p">{`type`}</strong>{`: required. one of `}<inlineCode parentName="p">{`awskms`}</inlineCode>{`, `}<inlineCode parentName="p">{`cloudkms`}</inlineCode>{`, `}<inlineCode parentName="p">{`pkcs11`}</inlineCode>{`, or `}<inlineCode parentName="p">{`yubikey`}</inlineCode></p>
          </li>
          <li parentName="ul">
            <p parentName="li"><strong parentName="p">{`uri`}</strong>{`: this field can be used to specify other fields in this section, and its value will take precedence over those values. See `}<a parentName="p" {...{
                "href": "#cryptographic-protection"
              }}>{`cryptographic protection`}</a>{` for examples.`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li"><strong parentName="p">{`region`}</strong>{`: for `}<inlineCode parentName="p">{`awskms`}</inlineCode>{`, the AWS region`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li"><strong parentName="p">{`profile`}</strong>{`: for `}<inlineCode parentName="p">{`awskms`}</inlineCode>{`, the AWS profile`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li"><strong parentName="p">{`credentialsFile`}</strong>{`: for `}<inlineCode parentName="p">{`cloudkms`}</inlineCode>{`, the path to a Google Cloud Platform credentials JSON file for a role that can access cloudkms`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li"><strong parentName="p">{`pin`}</strong>{`: for `}<inlineCode parentName="p">{`yubikey`}</inlineCode>{`, the PIN of the YubiKey PIV application`}</p>
          </li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`password`}</strong>{`: optionally store the password for decrypting the intermediate private key (this should be the same password you chose during PKI initialization). If the value is not stored in configuration then you will be prompted for it when starting the CA.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`address`}</strong>{`: ex. 127.0.0.1:8080 - address and port on which the CA will bind and respond to requests.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`dnsNames`}</strong>{`: comma separated list of DNS name(s) for the CA.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`logger`}</strong>{`: the default logging format for the CA is `}<em parentName="p">{`text`}</em>{`. The other option is `}<em parentName="p">{`json`}</em>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`ssh`}</strong>{`: enables the provisioning of SSH certificates by the CA. Add this section to an existing `}<inlineCode parentName="p">{`ca.json`}</inlineCode>{` to enable SSH for an existing CA. SSH keys can be created by running
`}<inlineCode parentName="p">{`step crypto keypair host.pub host.key`}</inlineCode>{` and `}<inlineCode parentName="p">{`step crypto keypair user.pub user.key`}</inlineCode>{`.`}</p>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li"><strong parentName="p">{`userKey`}</strong>{`: the signing key for user SSH certificates.`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li"><strong parentName="p">{`hostKey`}</strong>{`: the signing key for host SSH certificates.`}</p>
          </li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`db`}</strong>{`: data persistence layer. See `}<a parentName="p" {...{
            "href": "#databases"
          }}>{`database documentation`}</a>{` for more info.`}</p>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li"><strong parentName="p">{`type`}</strong>{`: badger, bbolt, mysql, or postgresql`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li"><strong parentName="p">{`dataSource`}</strong>{`: string that can be interpreted differently depending on the type of the database. Usually a path to where the data is stored. See the database configuration docs for more info.`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li"><strong parentName="p">{`database`}</strong>{`: name of the database. Used for back-ends that may have multiple databases. e.g. MySQL and PostgreSQL`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li"><strong parentName="p">{`valueDir`}</strong>{`: directory to store the value log in (Badger specific).`}</p>
          </li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`crl`}</strong>{`: Certificate Revocation List(CRL) settings. (experimental)`}</p>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li"><strong parentName="p">{`enabled`}</strong>{`: enables CRL generation (`}<inlineCode parentName="p">{`true`}</inlineCode>{` to generate, `}<inlineCode parentName="p">{`false`}</inlineCode>{` to disable). Default is false.`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li"><strong parentName="p">{`generateOnRevoke`}</strong>{`: a revoke will generate a new CRL if the `}<inlineCode parentName="p">{`crl`}</inlineCode>{` is enabled. Default is false.`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li"><strong parentName="p">{`cacheDuration`}</strong>{`: the duration until next update of the CRL. Defaults to 24h.`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li"><strong parentName="p">{`renewPeriod`}</strong>{`: the time between CRL regeneration. Defaults to ~2/3 of the
cacheDuration.`}</p>
          </li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`tls`}</strong>{`: settings for negotiating communication with the CA; includes acceptable ciphersuites, min/max TLS version, etc.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`authority`}</strong>{`: controls the request authorization and signature processes.`}</p>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li"><strong parentName="p">{`type`}</strong>{`: the type of backing CA service that issues certificates for this step-ca instance. The default is an internal certificate authority service.
Other options can `}<a parentName="p" {...{
                "href": "https:/prof.infra.smallstep.com/docs/step-ca/registration-authority-ra-mode"
              }}>{`turn `}<inlineCode parentName="a">{`step-ca`}</inlineCode>{` into a Registration Authority`}</a>{`: `}<inlineCode parentName="p">{`stepcas`}</inlineCode>{` uses a remote `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` instance as the backend, `}<inlineCode parentName="p">{`cloudcas`}</inlineCode>{` uses Google CloudCAS, `}<inlineCode parentName="p">{`vaultcas`}</inlineCode>{` uses Hashicorp Vault.`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li"><strong parentName="p">{`template`}</strong>{`: default ASN1DN values for new certificates.`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li"><strong parentName="p">{`claims`}</strong>{`: default validation for requested attributes in the certificate request. Can be overridden by similar claims objects defined by individual provisioners.`}</p>
            <ul parentName="li">
              <li parentName="ul">
                <p parentName="li"><strong parentName="p">{`minTLSCertDuration`}</strong>{`: do not allow certificates with a duration less than this value.`}</p>
              </li>
              <li parentName="ul">
                <p parentName="li"><strong parentName="p">{`maxTLSCertDuration`}</strong>{`: do not allow certificates with a duration greater than this value.`}</p>
              </li>
              <li parentName="ul">
                <p parentName="li"><strong parentName="p">{`defaultTLSCertDuration`}</strong>{`: if no certificate validity period is specified, use this value.`}</p>
              </li>
              <li parentName="ul">
                <p parentName="li"><strong parentName="p">{`disableRenewal`}</strong>{`: do not allow any certificates to be renewed. The default is false.`}</p>
              </li>
              <li parentName="ul">
                <p parentName="li"><strong parentName="p">{`allowRenewalAfterExpiry`}</strong>{`: ☠️  allow expired certificates to be renewed. The default is false. This option adds security risk; proceed with caution and consider alternatives.`}</p>
              </li>
              <li parentName="ul">
                <p parentName="li"><strong parentName="p">{`minUserSSHCertDuration`}</strong>{`: do not allow certificates with a duration less than this value.`}</p>
              </li>
              <li parentName="ul">
                <p parentName="li"><strong parentName="p">{`maxUserSSHCertDuration`}</strong>{`: do not allow certificates with a duration greater than this value.`}</p>
              </li>
              <li parentName="ul">
                <p parentName="li"><strong parentName="p">{`defaultUserSSHCertDuration`}</strong>{`: if no certificate validity period is specified, use this value.`}</p>
              </li>
              <li parentName="ul">
                <p parentName="li"><strong parentName="p">{`minHostSSHCertDuration`}</strong>{`: do not allow certificates with a duration less than this value.`}</p>
              </li>
              <li parentName="ul">
                <p parentName="li"><strong parentName="p">{`maxHostSSHCertDuration`}</strong>{`: do not allow certificates with a duration greater than this value.`}</p>
              </li>
              <li parentName="ul">
                <p parentName="li"><strong parentName="p">{`defaultHostSSHCertDuration`}</strong>{`: if no certificate validity period is specified, use this value.`}</p>
              </li>
              <li parentName="ul">
                <p parentName="li"><strong parentName="p">{`enableSSHCA`}</strong>{`: enable this provisioner to generate SSH Certificates. The default value is false.`}</p>
              </li>
            </ul>
          </li>
          <li parentName="ul">
            <p parentName="li"><strong parentName="p">{`disableIssuedAtCheck`}</strong>{`: ☠️  disable a check verifying that provisioning tokens must be issued after the CA has booted. This claim is one prevention
against token reuse. The default value is false. Do not change this unless you know what you are doing.`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li"><strong parentName="p">{`provisioners`}</strong>{`: list of provisioners. Each provisioner has a name, associated authentication attributes, and an optional claims attribute that will override any values set in the global claims directly underneath authority. The `}<a parentName="p" {...{
                "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/ca/provisioner"
              }}><inlineCode parentName="a">{`step ca provisioner`}</inlineCode></a>{` command group can be used to add, modify, and remove provisioners. See the `}<a parentName="p" {...{
                "href": "https:/prof.infra.smallstep.com/docs/step-ca/provisioners"
              }}>{`provisioner documentation`}</a>{` for details.`}</p>
            <ul parentName="li">
              <li parentName="ul">
                <p parentName="li"><strong parentName="p">{`claims`}</strong>{`: Each provisioner can define an optional `}<inlineCode parentName="p">{`claims`}</inlineCode>{` attribute. The settings in this attribute override any settings in the global `}<inlineCode parentName="p">{`claims`}</inlineCode>{` attribute in the authority configuration. See the authority's `}<strong parentName="p">{`claims`}</strong>{` section above for a complete list of options.`}</p>
              </li>
              <li parentName="ul">
                <p parentName="li"><strong parentName="p">{`options`}</strong>{`: Each provisioner can define an optional `}<inlineCode parentName="p">{`options`}</inlineCode>{` attribute. This attribute allows an operator to set templates that will be applied to all X.509 or SSH certificates generated using the provisioner. See the `}<a parentName="p" {...{
                    "href": "https:/prof.infra.smallstep.com/docs/step-ca/templates"
                  }}>{`templates documentation`}</a>{` for details.`}</p>
              </li>
            </ul>
          </li>
          <li parentName="ul">
            <p parentName="li"><strong parentName="p">{`password`}</strong>{`: ☠️  plain text password for starting the CA. Used to decrypt the intermediate private key. `}</p>
            <Alert severity="warning" mdxType="Alert">
  <div> 
    We do not recommend storing the password plain text in your <Code mdxType="Code">ca.json</Code>. 
    The recommended option is to use the <Code mdxType="Code">--password-file</Code> flag when
    running <Code mdxType="Code">step-ca</Code>. This attribute is a convenience that should be 
    avoided in production.
  </div>
            </Alert>
          </li>
        </ul>
      </li>
    </ul>
    <h2>{`Provisioners`}</h2>
    <p>{`Provisioners are people or entities that are registered with the certificate authority and
authorized to issue certificates. Visit the `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-ca/provisioners"
      }}><inlineCode parentName="a">{`step-ca`}</inlineCode>{` provisioners`}</a>{` page to learn about the
different provisioners, their target use cases, and how to add, remove, and configure them.`}</p>
    <h2>{`Policy`}</h2>
    <p>{`Certificate issuance policies can be used to enforce which Subjects, SANs and Principals the CA is allowed to sign.
They can be configured for X.509 certificates as well as SSH user and host certificates.
Policies are evaluated before a certificate is signed.
Some examples of policies you can configure are:`}</p>
    <ul>
      <li parentName="ul">{`A `}<inlineCode parentName="li">{`dns`}</inlineCode>{` rule for `}<inlineCode parentName="li">{`www.example.com`}</inlineCode>{`, only matching the domain `}<inlineCode parentName="li">{`www.example.com`}</inlineCode></li>
      <li parentName="ul">{`A `}<inlineCode parentName="li">{`dns`}</inlineCode>{` rule for `}<inlineCode parentName="li">{`*.internal.example.com`}</inlineCode>{`, matching all subdomains of `}<inlineCode parentName="li">{`internal.example.com`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`An `}<inlineCode parentName="li">{`ip`}</inlineCode>{` rule for the `}<inlineCode parentName="li">{`192.168.0.0/24`}</inlineCode>{` CIDR, matching all IPs in the range from `}<inlineCode parentName="li">{`192.168.0.0`}</inlineCode>{`-`}<inlineCode parentName="li">{`192.168.0.255`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`An `}<inlineCode parentName="li">{`email`}</inlineCode>{` rule for `}<inlineCode parentName="li">{`@devops`}</inlineCode>{`, matching all SSH user principals in the `}<inlineCode parentName="li">{`@devops`}</inlineCode>{` domain.`}</li>
      <li parentName="ul">{`A `}<inlineCode parentName="li">{`uri`}</inlineCode>{` rule for `}<inlineCode parentName="li">{`*.example.com`}</inlineCode>{`, matching all URIs for subdomains of `}<inlineCode parentName="li">{`example.com`}</inlineCode>{`, ignoring the URI scheme, path, query and fragment parts.`}</li>
    </ul>
    <p>{`Visit the `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-ca/policies"
      }}><inlineCode parentName="a">{`step-ca`}</inlineCode>{` policy`}</a>{` page to learn how certificate issuance policies work and how they can be configured.`}</p>
    <Alert severity="info" mdxType="Alert">
  <AlertTitle mdxType="AlertTitle">Need more control?</AlertTitle>
  <div>
    A self-hosted <Code mdxType="Code">step-ca</Code> instance can be configured with a policy on the authority level only.
    In a <Link external href="https://smallstep.com/signup?product=cm" mdxType="Link">
      free hosted smallstep Certificate Manager authority
    </Link>, policies can be configured on the authority, on each provisioner, and on every ACME account.
  </div>
    </Alert>
    <h2>{`Templates`}</h2>
    <p>{`Using X.509 and SSH certificate templates administrators can configure information that gets processed and added to certificates.
These include things like:`}</p>
    <ul>
      <li parentName="ul">{`Custom SANs or extensions for X.509 certificates`}</li>
      <li parentName="ul">{`Create longer certificate chains, including multiple intermediate CAs`}</li>
      <li parentName="ul">{`Embed SSH `}<inlineCode parentName="li">{`force-command`}</inlineCode>{` or `}<inlineCode parentName="li">{`source-address`}</inlineCode>{` extensions`}</li>
      <li parentName="ul">{`Evaluate basic logic operations on certificate's parameters, and fail if requirements are not met`}</li>
    </ul>
    <p>{`Visit the `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-ca/templates"
      }}><inlineCode parentName="a">{`step-ca`}</inlineCode>{` templates`}</a>{` page to learn how to use templates.`}</p>
    <h2>{`Databases`}</h2>
    <p><inlineCode parentName="p">{`step-ca`}</inlineCode>{` uses a simple key-value interface over popular database
implementations to store persistent certificate management meta-data. Our
recommended default database implementation is nosql-Badger, a NoSQL interface
over the popular `}<a parentName="p" {...{
        "href": "https://github.com/dgraph-io/badger"
      }}>{`Badger`}</a>{` database. As a
first pass, the database layer will store every certificate (along with
metadata surrounding the provisioning of the certificate) and
revocation data that will be used to enforce passive revocation.`}</p>
    <p>{`Configuring `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` to use a database is as simple as adding a top-level `}<inlineCode parentName="p">{`db`}</inlineCode>{`
stanza to your `}<inlineCode parentName="p">{`ca.json`}</inlineCode>{` file. Below users can find documentation and examples
for supported databases:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#badger"
        }}>{`Badger`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#boltdb"
        }}>{`BoltDB`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#mysql"
        }}>{`MySQL`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#postgresql"
        }}>{`PostgreSQL`}</a></li>
    </ul>
    <h3>{`Badger`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  ...
  "db": {
    "type": "badger",
    "dataSource": "./.step/db",
    "valueDir": "./.step/valuedb"
    "badgerFileLoadingMode": "MemoryMap"
  },
  ...
},
`}</code></pre>
    <p><strong parentName="p">{`Options`}</strong></p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`type`}</inlineCode></p>
        <ul parentName="li">
          <li parentName="ul"><strong parentName="li">{`badger`}</strong>{`: currently refers to Badger V1. However, as Badger V1 is deprecated, this will refer to Badger V2 starting with a the next major version release.`}</li>
          <li parentName="ul"><strong parentName="li">{`badgerV1`}</strong>{`: explicitly select Badger V1.`}</li>
          <li parentName="ul"><strong parentName="li">{`badgerV2`}</strong>{`: explicitly select Badger V2. Anyone looking to use Badger V2 will need to set it explicitly until it becomes the default.`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`dataSource`}</inlineCode>{`: path, database directory.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`valueDir`}</inlineCode>{` `}{`[optional]`}{`: path, value directory, only if different from dataSource.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`badgerFileLoadingMode`}</inlineCode>{` `}{`[optional]`}{`: can be set to FileIO (instead of the default MemoryMap) to avoid memory-mapping log files. This can be useful in environments with low RAM. Make sure to use badgerV2 as the database type if using this option.`}</p>
        <ul parentName="li">
          <li parentName="ul"><strong parentName="li">{`MemoryMap`}</strong>{`: default.`}</li>
          <li parentName="ul"><strong parentName="li">{`FileIO`}</strong>{`: This can be useful in environments with low RAM.`}</li>
        </ul>
      </li>
    </ul>
    <h3>{`BoltDB`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  ...
  "db": {
    "type": "bbolt",
    "dataSource": "./stepdb"
  },
  ...
}
`}</code></pre>
    <p><strong parentName="p">{`Options`}</strong></p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`type`}</inlineCode>{`: `}<strong parentName="li">{`bbolt`}</strong></li>
      <li parentName="ul"><inlineCode parentName="li">{`dataSource`}</inlineCode>{`: path, database directory.`}</li>
    </ul>
    <h3>{`MySQL`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  ...
  "db": {
    "type": "mysql",
    "dataSource": "user:password@tcp(127.0.0.1:3306)/",
    "database": "myDBName"
  },
  ...
},
`}</code></pre>
    <p><strong parentName="p">{`Options`}</strong></p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`type`}</inlineCode>{`: `}<strong parentName="li">{`mysql`}</strong></li>
      <li parentName="ul"><inlineCode parentName="li">{`dataSource`}</inlineCode>{`: path, database directory.`}</li>
    </ul>
    <Alert severity="info" mdxType="Alert">
  <div>
    Extra parameters can be passed in the mysql dataSource name as follows: <Code mdxType="Code">"dataSource": "user:password@tcp(127.0.0.1:3306)/myDBName?tls=true"</Code>.
  </div>
    </Alert>
    <h3>{`PostgreSQL`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  ...
  "db": {
    "type": "postgresql",
    "dataSource": "postgresql://user:password@127.0.0.1:5432/",
    "database": "myDBName"
  },
  ...
},
`}</code></pre>
    <h4>{`Options`}</h4>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`type`}</inlineCode>{`: `}<strong parentName="li">{`postgresql`}</strong></li>
      <li parentName="ul"><inlineCode parentName="li">{`dataSource`}</inlineCode>{`: a PostgreSQL DSN.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`database`}</inlineCode>{`: database name. Takes precedence over name in the DSN if provided.`}</li>
    </ul>
    <Alert severity="info" mdxType="Alert">
  <div>
    Extra parameters can be passed in the PostgreSQL dataSource name as follows: <Code mdxType="Code">"dataSource": "postgresql://user:password@127.0.0.1:5432/myDBName?connect_timeout=10"</Code>.
  </div>
    </Alert>
    <h4>{`Use TLS with PostgreSQL`}</h4>
    <p>{`The PostgreSQL DSN can be used to configure TLS settings for connecting to the server.
An example database configuration that enables TLS server hostname verification is shown below:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  ...
  "db": {
    "type": "postgresql",
    "dataSource": "postgresql://user:password@127.0.0.1:5432/dbname?sslmode=verify-full",
  },
  ...
},
`}</code></pre>
    <p>{`The database driver will look for a file with root CAs to trust in `}<inlineCode parentName="p">{`$HOME/.postgresql/root.crt`}</inlineCode>{`, but will use the system CA trust store if that file is not found.
Similarly, `}<inlineCode parentName="p">{`$HOME/.postgresql/postgresql.crt`}</inlineCode>{` and the corresponding `}<inlineCode parentName="p">{`$HOME/.postgresql/postgresql.key`}</inlineCode>{` will be used for mutual TLS authentication if these files exist and if the server requests a client certificate.
It is also possible to override the locations for these files by providing them in the DSN:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  ...
  "db": {
    "type": "postgresql",
    "dataSource": "postgresql://user:password@127.0.0.1:5432/dbname?sslmode=verify-full&sslrootcert=/path/to/roots.pem&sslcert=/path/to/client.pem&sslkey=/path/to/client.key",
  },
  ...
},
`}</code></pre>
    <p>{`The behavior of the PostgreSQL database driver mimics that of `}<inlineCode parentName="p">{`libpq`}</inlineCode>{` and also encompasses handling of other default settings and environment variables.
More info about TLS options and the DSN query parameters for `}<inlineCode parentName="p">{`libpq`}</inlineCode>{` can be found at `}<a parentName="p" {...{
        "href": "https://www.postgresql.org/docs/current/libpq-ssl.html"
      }}>{`https://www.postgresql.org/docs/current/libpq-ssl.html`}</a>{`.`}</p>
    <h3>{`Schema`}</h3>
    <p>{`As the interface is a key-value store, the schema is very simple. We support tables, keys, and values. An entry in the database is a `}<inlineCode parentName="p">{`[]byte value`}</inlineCode>{` that is indexed by `}<inlineCode parentName="p">{`[]byte table`}</inlineCode>{` and `}<inlineCode parentName="p">{`[]byte key`}</inlineCode>{`.`}</p>
    <h3>{`Exporting Data`}</h3>
    <p>{`At this time `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` does not have any API or interface for easily exporting data. Because the data is stored in a `}<inlineCode parentName="p">{`noSQL`}</inlineCode>{` like manner, it is difficult to explore the data even when using a SQL backend like MySQL. We do have `}<a parentName="p" {...{
        "href": "https://gist.github.com/dopey/89ec20f22c66c1333bf38c9b19b89758"
      }}>{`a scripted example for accessing the DB`}</a>{` to give users a jumping off point for writing their own reporting and logging tools.`}</p>
    <Alert severity="info" mdxType="Alert">
  <div>
    Note that some DBs, like Badger and BoltDB, cannot have multiple processes accessing them simultaneously. You'll need to 
    stop the <Code mdxType="Code">step-ca</Code> process in order to run a export script against those DBs.
  </div>
    </Alert>
    <h2>{`Cryptographic Protection`}</h2>
    <p>{`By default, `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` stores its signing keys encrypted on disk.`}</p>
    <p>{`For security hardening,
you may desire more advanced cryptographic protection (or hardware protection)
of your CA's signing keys.`}</p>
    <p>{`For these scenarios, `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` integrates with the following key management systems:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#google-cloud-kms"
        }}>{`Google Cloud KMS`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#aws-kms"
        }}>{`AWS KMS`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#azure-key-vault"
        }}>{`Azure Key Vault`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#pkcs-11"
        }}>{`PKCS #11 hardware security modules (HSMs)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#yubikey-piv"
        }}>{`YubiKey PIV`}</a></li>
      <li parentName="ul">{`ssh-agent`}</li>
    </ul>
    <p>{`For a complete, end-to-end example using a YubiKey,
see our blog post `}<a parentName="p" {...{
        "href": "https://smallstep.com/blog/build-a-tiny-ca-with-raspberry-pi-yubikey/"
      }}>{`Build a Tiny Certificate Authority For Your Homelab`}</a>{`.`}</p>
    <h3>{`Before You Begin`}</h3>
    <p>{`For now, the `}<inlineCode parentName="p">{`step ca init`}</inlineCode>{` command has very limited KMS support. We've created the `}<a parentName="p" {...{
        "href": "https://github.com/smallstep/step-kms-plugin"
      }}><inlineCode parentName="a">{`step kms`}</inlineCode>{` plugin`}</a>{` for managing the keys and certificates on cloud KMSs and on hardware devices. Please install this plugin before continuing with any of the examples below.`}</p>
    <h3>{`Google Cloud KMS`}</h3>
    <p><a parentName="p" {...{
        "href": "https://cloud.google.com/kms/docs"
      }}>{`Cloud KMS`}</a>{` is Google's cloud-hosted KMS that allows you to store the cryptographic keys and sign certificates using their infrastructure. Cloud KMS supports two key protection levels: `}<inlineCode parentName="p">{`SOFTWARE`}</inlineCode>{` and `}<inlineCode parentName="p">{`HSM`}</inlineCode>{`.`}</p>
    <h4>{`Creating your PKI in Google Cloud KMS`}</h4>
    <p>{`Please install the `}<a parentName="p" {...{
        "href": "https://github.com/smallstep/step-kms-plugin"
      }}><inlineCode parentName="a">{`step kms`}</inlineCode>{` plugin`}</a>{` before you begin. You'll need it to create your PKI.`}</p>
    <p>{`Also, make sure you have installed `}<a parentName="p" {...{
        "href": "https://cloud.google.com/sdk/docs/install"
      }}>{`the `}<inlineCode parentName="a">{`gcloud`}</inlineCode>{` CLI`}</a>{` and have `}<a parentName="p" {...{
        "href": "https://developers.google.com/accounts/docs/application-default-credentials"
      }}>{`configured Google Cloud application default credentials`}</a>{` in your local environment, eg. by running `}<inlineCode parentName="p">{`gcloud auth application-default login`}</inlineCode>{`.`}</p>
    <p>{`Next, let's generate a private key for your root CA inside Google Cloud KMS. Run:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ step kms create --json --kms 'cloudkms:' \\
    'projects/smallstep/locations/global/keyRings/step-ca/cryptoKeys/root'
`}</code></pre>
    <p>{`To constructe the key name parameter, use the resource name of the `}<a parentName="p" {...{
        "href": "https://cloud.google.com/kms/docs/reference/rest/v1/projects.locations.keyRings.cryptoKeys#CryptoKey"
      }}>{`CryptoKey`}</a>{` object you want to create, following the format:`}</p>
    <pre><code parentName="pre" {...{}}>{`projects/<project-id>/locations/<location>/keyRings/<key-ring-id>/cryptoKeys/<key-id>
`}</code></pre>
    <p>{`Once the key is generated, `}<inlineCode parentName="p">{`step`}</inlineCode>{` will output the key's name (including a version number), and the public key PEM:`}</p>
    <pre><code parentName="pre" {...{}}>{`{
  "name": "projects/smallstep/locations/global/keyRings/step-ca/cryptoKeys/root/cryptoKeyVersions/1",
  "publicKey": "-----BEGIN PUBLIC KEY-----\\nMFkwEwYHKoZIzj0CAQYIKoZIzj0DAQcDQgAERcTjeMNjBs29ReA1nf6Odyn2l4Yt\\nXPo4CUOcCrn6yw7LJmzaDiqIErhuS9r6BNg92kJvFUiuiU8+w+WZOyhZdw==\\n-----END PUBLIC KEY-----\\n"
}
`}</code></pre>
    <p>{`You'll need this key name for the next step.`}</p>
    <Alert severity="info" mdxType="Alert">
  <div>
	We have taken care to provide sane defaults in `step kms create`, but you may wish to change the key type, size, protection level, or other options for your environment. See <Code mdxType="Code">step kms help create</Code> for details.
  </div>
    </Alert>
    <p>{`Now, let's sign a root CA certificate based on the the key you just created. Substitute the key name output from `}<inlineCode parentName="p">{`step kms create`}</inlineCode>{` here:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ step certificate create --profile root-ca \\
   --kms 'cloudkms:' \\
   --key 'projects/smallstep/locations/global/keyRings/step-ca/cryptoKeys/root/cryptoKeyVersions/1' \\
   "Smallstep Root CA" root_ca.crt
`}</code></pre>
    <p>{`Output:`}</p>
    <pre><code parentName="pre" {...{}}>{`Your certificate has been saved in root_ca.crt.
`}</code></pre>
    <p>{`Great. Next, we'll repeat the process for the Intermediate CA:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ step kms create --json --kms 'cloudkms:' \\
    'projects/smallstep/locations/global/keyRings/step-ca/cryptoKeys/intermediate'
$ step certificate create --profile intermediate-ca \\
   --kms 'cloudkms:' \\
   --ca root_ca.crt \\
   --ca-key 'projects/smallstep/locations/global/keyRings/step-ca/cryptoKeys/root/cryptoKeyVersions/1' \\
   --key 'projects/smallstep/locations/global/keyRings/step-ca/cryptoKeys/intermediate/cryptoKeyVersions/1' \\
   "Smallstep Intermediate CA" intermediate_ca.crt
`}</code></pre>
    <p>{`Output:`}</p>
    <pre><code parentName="pre" {...{}}>{`Your certificate has been saved in intermediate_ca.crt.
`}</code></pre>
    <p>{`Now you should have both `}<inlineCode parentName="p">{`root_ca.crt`}</inlineCode>{` and `}<inlineCode parentName="p">{`intermediate_ca.crt`}</inlineCode>{` certificate PEM files.
You'll need these files for your CA configuration, below.`}</p>
    <p>{`If you want to run an SSH CA, you'll also need to create SSH CA key pairs:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ step kms create --json --kms 'cloudkms:' \\
    'projects/smallstep/locations/global/keyRings/step-ca/cryptoKeys/ssh-user'
$ step kms create --json --kms 'cloudkms:' \\
    'projects/smallstep/locations/global/keyRings/step-ca/cryptoKeys/ssh-host'
`}</code></pre>
    <h4>{`Configuring `}<inlineCode parentName="h4">{`step-ca`}</inlineCode>{` to use Google Cloud KMS`}</h4>
    <p>{`Next, to configure Cloud KMS signing in `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{`, start with a basic CA configuration created using `}<inlineCode parentName="p">{`step ca init`}</inlineCode>{`. Add the `}<inlineCode parentName="p">{`kms`}</inlineCode>{` object to your `}<inlineCode parentName="p">{`ca.json`}</inlineCode>{` file and replace the property `}<inlineCode parentName="p">{`key`}</inlineCode>{` with the Cloud KMS resource name of your intermediate key:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`{
    "root": "/etc/step-ca/certs/root_ca.crt",
    "crt": "/etc/step-ca/certs/intermediate_ca.crt",
    "key": "projects/smallstep/locations/global/keyRings/step-ca/cryptoKeys/intermediate/cryptoKeyVersions/1",
    "kms": {
        "type": "cloudkms",
        "uri": "cloudkms:credentials-file=/path/to/gcloud-kms-credentials.json"
    }
}
`}</code></pre>
    <p>{`Finally, copy the `}<inlineCode parentName="p">{`root_ca.crt`}</inlineCode>{` and `}<inlineCode parentName="p">{`intermediate_ca.crt`}</inlineCode>{` files into the `}<inlineCode parentName="p">{`root`}</inlineCode>{` and `}<inlineCode parentName="p">{`crt`}</inlineCode>{` locations:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ cp root_ca.crt intermediate_ca.crt $(step path)/certs
`}</code></pre>
    <p>{`Your X.509 CA is ready.`}</p>
    <p>{`To add SSH support, change the SSH key locations to Cloud KMS resource names for the SSH host and user CA keys:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`{
    "ssh": {
        "hostKey": "projects/smallstep/locations/global/keyRings/step-ca/cryptoKeys/ssh-host/cryptoKeyVersions/1",
        "userKey": "projects/smallstep/locations/global/keyRings/step-ca/cryptoKeys/ssh-user/cryptoKeyVersions/1"
    }
}
`}</code></pre>
    <p>{`When you start `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{`, you will see your X.509 root fingerprint, and the SSH user and host CA keys in SSH key format:`}</p>
    <pre><code parentName="pre" {...{}}>{`2022/09/20 16:28:45 The primary server URL is https://localhost:443
2022/09/20 16:28:45 Root certificates are available at https://localhost:443/roots.pem
2022/09/20 16:28:45 X.509 Root Fingerprint: b061dfca1013c074244b0f376e5be70b6eb0bd7f21d5438aa3af71fe62b0acf5
2022/09/20 16:28:45 SSH Host CA Key: ecdsa-sha2-nistp256 AAAAE2VjZHNhLXNoYTItbmlzdHAyNTYAAAAIbmlzdHAyNTYAAABBBIwmL7aDOJId/9UOVJGhVux6Rlvea+q2017aLsfze+/EwGQ5BdZ4k2Qh+5VekebBKZYLNO0LkSf9bZb4o9GSxIs=
2022/09/20 16:28:45 SSH User CA Key: ecdsa-sha2-nistp256 AAAAE2VjZHNhLXNoYTItbmlzdHAyNTYAAAAIbmlzdHAyNTYAAABBBFE0VY9eLxkoHrXoWk5VxeOQTUt53U5xIo89pfsgYHh450cdE4c3mYw5YeOueESyu/lFUHfJoNS6twVR1wuCOdc=
2022/09/20 16:28:45 Serving HTTPS on :443 ...
`}</code></pre>
    <h3>{`AWS KMS`}</h3>
    <p><a parentName="p" {...{
        "href": "https://aws.amazon.com/kms/"
      }}>{`AWS KMS`}</a>{` is Amazon's managed encryption and key management service. It creates and stores the cryptographic keys and uses AWS infrastructure for signing operations. Amazon KMS operations are always backed by HSMs.`}</p>
    <h4>{`Creating your PKI in AWS KMS`}</h4>
    <p>{`Please install the `}<a parentName="p" {...{
        "href": "https://github.com/smallstep/step-kms-plugin"
      }}><inlineCode parentName="a">{`step kms`}</inlineCode>{` plugin`}</a>{` before you begin. You'll need it to create your PKI.`}</p>
    <p>{`Also, make sure you have installed `}<a parentName="p" {...{
        "href": "https://aws.amazon.com/cli/"
      }}>{`the `}<inlineCode parentName="a">{`aws`}</inlineCode>{` CLI`}</a>{` and have configured AWS credentials in your local environment.`}</p>
    <Alert severity="info" mdxType="Alert">
  <div>
	Avoid storing local AWS credentials in plaintext, if you can. We prefer either <a href="https://docs.aws.amazon.com/singlesignon/latest/userguide/step1.html">AWS Identity Center</a> or <a href="https://github.com/99designs/aws-vault">aws-vault</a> for securing local credentials.
  </div>
    </Alert>
    <p>{`Next, let's generate a private key for your root CA inside AWS KMS. Run:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ step kms create --json --kms 'awskms:region=us-east-2' root-ca
`}</code></pre>
    <p>{`Once the key is generated, `}<inlineCode parentName="p">{`step`}</inlineCode>{` will output the key ID and the public key PEM:`}</p>
    <pre><code parentName="pre" {...{}}>{`{
  "name": "awskms:key-id=78980acd-a42d-4d84-97ba-1e50d3082214",
  "publicKey": "-----BEGIN PUBLIC KEY-----\\nMFkwEwYHKoZIzj0CAQYIKoZIzj0DAQcDQgAEH2ls6h1y2jsXV+IeuhDVkb68zkMe\\nKPtI7L6vBIa5ThxOyFaZFnUrGWU6B+KQjProAntgKyNTqOnAh7Eyr3RmgQ==\\n-----END PUBLIC KEY-----\\n"
}
`}</code></pre>
    <p>{`You'll need this key name for the next step.`}</p>
    <Alert severity="info" mdxType="Alert">
  <div>
	We have taken care to provide sane defaults in `step kms create`, but you may wish to change the key type, size, protection level, or other options for your environment. See <Code mdxType="Code">step kms help create</Code> for details.
  </div>
    </Alert>
    <p>{`Now, let's sign a root CA certificate based on the the key you just created. Substitute the key name output from `}<inlineCode parentName="p">{`step kms create`}</inlineCode>{` here:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ step certificate create --profile root-ca \\
   --kms 'awskms:region=us-east-2' \\
   --key 'awskms:key-id=78980acd-a42d-4d84-97ba-1e50d3082214' \\
   "Smallstep Root CA" root_ca.crt
`}</code></pre>
    <p>{`Output:`}</p>
    <pre><code parentName="pre" {...{}}>{`Your certificate has been saved in root_ca.crt.
`}</code></pre>
    <p>{`Great. Next, we'll repeat the process for the Intermediate CA:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ step kms create --json --kms 'awskms:region=us-east-2' intermediate-ca
$ step certificate create --profile intermediate-ca \\
   --kms 'awskms:region=us-east-2' \\
   --ca root_ca.crt \\
   --ca-key 'awskms:key-id=78980acd-a42d-4d84-97ba-1e50d3082214' \\
   --key 'awskms:key-id=9432458d-1e67-4a74-9a23-8f94708b45fe' \\
   "Smallstep Intermediate CA" intermediate_ca.crt
`}</code></pre>
    <p>{`Here, the `}<inlineCode parentName="p">{`--ca-key`}</inlineCode>{` is the root CA key id; the `}<inlineCode parentName="p">{`--key`}</inlineCode>{` is the intermediate CA key id.`}</p>
    <p>{`Output:`}</p>
    <pre><code parentName="pre" {...{}}>{`Your certificate has been saved in intermediate_ca.crt.
`}</code></pre>
    <p>{`Now you should have both `}<inlineCode parentName="p">{`root_ca.crt`}</inlineCode>{` and `}<inlineCode parentName="p">{`intermediate_ca.crt`}</inlineCode>{` certificate PEM files.
You'll need these files for your CA configuration, below.`}</p>
    <p>{`If you want to run an SSH CA, you'll also need to create SSH CA key pairs:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ step kms create --json --kms 'awskms:region=us-east-2' ssh-user-ca
$ step kms create --json --kms 'awskms:region=us-east-2' ssh-host-ca
`}</code></pre>
    <p>{`Hold onto the key IDs from these commands; you'll need them below.`}</p>
    <h4>{`Configuring `}<inlineCode parentName="h4">{`step-ca`}</inlineCode>{` to use AWS KMS`}</h4>
    <p>{`To use `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` with AWS KMS, create a scoped IAM role that has `}<inlineCode parentName="p">{`kms:GetPublicKey`}</inlineCode>{` permissions on all of your CA keys, and `}<inlineCode parentName="p">{`kms:Sign`}</inlineCode>{` permission on your intermediate CA key.`}</p>
    <p>{`To configure AWS KMS in your certificate authority, add the `}<inlineCode parentName="p">{`kms`}</inlineCode>{` object to `}<inlineCode parentName="p">{`ca.json`}</inlineCode>{` and replace the `}<inlineCode parentName="p">{`key`}</inlineCode>{` property with the AWS KMS key ID of your intermediate CA key:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`{
    "root": "/etc/step-ca/certs/root_ca.crt",
    "crt": "/etc/step-ca/certs/intermediate_ca.crt",
    "key": "awskms:key-id=f879f239-feb6-4596-9ed2-b1606277c7fe",
    "kms": {
        "type": "awskms",
        "uri": "awskms:region=us-east-2;profile=foo;credentials-file=/path/to/credentials"
    }
}
`}</code></pre>
    <p>{`By default, `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` (and, more broadly, AWS's SDK) will look for credentials stored in `}<inlineCode parentName="p">{`~/.aws/credentials`}</inlineCode>{`. Use the `}<inlineCode parentName="p">{`credentials-file`}</inlineCode>{` option to override. The `}<inlineCode parentName="p">{`region`}</inlineCode>{` and `}<inlineCode parentName="p">{`profile`}</inlineCode>{` options specify the AWS region and configuration profiles respectively. These options can also be configured using environment variables as described in the `}<a parentName="p" {...{
        "href": "https://docs.aws.amazon.com/sdk-for-go/api/aws/session/"
      }}>{`AWS SDK for Go session documentation`}</a>{`.`}</p>
    <p>{`Finally, copy the `}<inlineCode parentName="p">{`root_ca.crt`}</inlineCode>{` and `}<inlineCode parentName="p">{`intermediate_ca.crt`}</inlineCode>{` files into the `}<inlineCode parentName="p">{`root`}</inlineCode>{` and `}<inlineCode parentName="p">{`crt`}</inlineCode>{` locations:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ cp root_ca.crt intermediate_ca.crt $(step path)/certs
`}</code></pre>
    <p>{`Your X.509 CA is ready.`}</p>
    <p>{`To configure an SSH CA, replace the SSH key locations with the SSH CA keys you created above:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`{
    "ssh": {
        "hostKey": "awskms:key-id=d48e502a-09bc-4bf7-9af8-ae1bccedc931",
        "userKey": "awskms:key-id=cf28e942-1e10-4a08-b84c-5359af1b5f12"
    }
}
`}</code></pre>
    <p>{`When you start `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{`, you will see your X.509 root fingerprint, and the SSH user and host CA keys in SSH key format:`}</p>
    <pre><code parentName="pre" {...{}}>{`2022/09/20 16:28:45 The primary server URL is https://localhost:443
2022/09/20 16:28:45 Root certificates are available at https://localhost:443/roots.pem
2022/09/20 16:28:45 X.509 Root Fingerprint: b061dfca1013c074244b0f376e5be70b6eb0bd7f21d5438aa3af71fe62b0acf5
2022/09/20 16:28:45 SSH Host CA Key: ecdsa-sha2-nistp256 AAAAE2VjZHNhLXNoYTItbmlzdHAyNTYAAAAIbmlzdHAyNTYAAABBBIwmL7aDOJId/9UOVJGhVux6Rlvea+q2017aLsfze+/EwGQ5BdZ4k2Qh+5VekebBKZYLNO0LkSf9bZb4o9GSxIs=
2022/09/20 16:28:45 SSH User CA Key: ecdsa-sha2-nistp256 AAAAE2VjZHNhLXNoYTItbmlzdHAyNTYAAAAIbmlzdHAyNTYAAABBBFE0VY9eLxkoHrXoWk5VxeOQTUt53U5xIo89pfsgYHh450cdE4c3mYw5YeOueESyu/lFUHfJoNS6twVR1wuCOdc=
2022/09/20 16:28:45 Serving HTTPS on :443 ...
`}</code></pre>
    <h3>{`Azure Key Vault`}</h3>
    <p><a parentName="p" {...{
        "href": "https://azure.microsoft.com/en-us/services/key-vault/#product-overview"
      }}>{`Azure Key Vault`}</a>{` is Microsoft's managed key management service.`}</p>
    <h4>{`Authentication`}</h4>
    <p>{`When using Azure Key Vault with `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{`, you will first need to authenticate to Azure.
Authentication to Azure is handled via environment variables;
we recommend using either `}<a parentName="p" {...{
        "href": "https://docs.microsoft.com/en-us/azure/developer/go/azure-sdk-authorization#use-file-based-authentication"
      }}>{`file-based authentication`}</a>{` via the `}<inlineCode parentName="p">{`AZURE_AUTH_LOCATION`}</inlineCode>{` environment variable,
or `}<a parentName="p" {...{
        "href": "https://docs.microsoft.com/en-us/azure/developer/go/azure-sdk-authentication-managed-identity?tabs=azure-cli"
      }}>{`using a managed identity`}</a>{` and setting the  `}<inlineCode parentName="p">{`AZURE_TENANT_ID`}</inlineCode>{` and `}<inlineCode parentName="p">{`AZURE_CLIENT_ID`}</inlineCode>{` variables when starting `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{`.
Alternatively, you can create a `}<a parentName="p" {...{
        "href": "https://docs.microsoft.com/en-us/cli/azure/create-an-azure-service-principal-azure-cli"
      }}>{`service principal`}</a>{` and set the `}<inlineCode parentName="p">{`AZURE_TENANT_ID`}</inlineCode>{`, `}<inlineCode parentName="p">{`AZURE_CLIENT_ID`}</inlineCode>{`, and `}<inlineCode parentName="p">{`AZURE_CLIENT_SECRET`}</inlineCode>{` variables.
See Option 1 under `}<a parentName="p" {...{
        "href": "https://docs.microsoft.com/en-us/azure/developer/go/azure-sdk-authentication?tabs=bash#-option-1-define-environment-variables"
      }}>{`Authentication Methods for Azure SDK for Go`}</a>{` for examples of authentication methods and environment variables.`}</p>
    <p>{`For local development and testing, Azure CLI credentials will be used if no authentication environment variables are set.`}</p>
    <h4>{`Initialize a PKI`}</h4>
    <p>{`To initialize a PKI backed by Azure Key Vault, start by authenticating to Azure using one of the above approaches.
Set the environment variables necessary for authentication to your tenant.`}</p>
    <p>{`Then, run:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ step ca init --kms azurekms
`}</code></pre>
    <p>{`You will be walked through the process of creating root and intermediate CA signing keys in Key Vault, and configuring the CA to use them.
If you're creating an SSH CA, SSH host and user CA keys will be created in Key Vault as well.`}</p>
    <h4>{`Manual Configuration`}</h4>
    <p>{`To configure an existing CA for Azure Key Vault, or to import an existing Azure Key Vault signing key, add the `}<inlineCode parentName="p">{`kms`}</inlineCode>{` object to your `}<inlineCode parentName="p">{`ca.json`}</inlineCode>{`, and replace the `}<inlineCode parentName="p">{`key`}</inlineCode>{` properties with the key name, vault name, and version of your intermediate (signing) key in Azure Key Vault:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`{
    "root": "/etc/step-ca/certs/root_ca.crt",
    "crt": "/etc/step-ca/certs/intermediate_ca.crt",
    "key": "azurekms:name=intermediate-ca-key;vault=example-vault-0?version=15faf8b8b80d4f1ead067c6383a38b8f&hsm=true",
    "kms": {
        "type": "azurekms"
    }
}
`}</code></pre>
    <ul>
      <li parentName="ul">{`In the `}<inlineCode parentName="li">{`key`}</inlineCode>{` URI, the `}<inlineCode parentName="li">{`name`}</inlineCode>{` and `}<inlineCode parentName="li">{`vault`}</inlineCode>{` refer to the key name and vault name of your intermediate key in Azure Key Vault.`}</li>
      <li parentName="ul">{`In the `}<inlineCode parentName="li">{`key`}</inlineCode>{` URI, the `}<inlineCode parentName="li">{`version`}</inlineCode>{` is the version of the Azure Key Vault key name. Though it is optional, we recommend setting this value explicitly. If omitted, the latest version will be used.`}</li>
      <li parentName="ul">{`In the `}<inlineCode parentName="li">{`key`}</inlineCode>{` URI, the optional `}<inlineCode parentName="li">{`hsm`}</inlineCode>{` property can be set to `}<inlineCode parentName="li">{`true`}</inlineCode>{` if HSM protection is desired. This is only used when the key is being created by `}<inlineCode parentName="li">{`step-ca`}</inlineCode>{`. The default is to use software-protected (non-HSM-backed) keys. See Key Vault's `}<a parentName="li" {...{
          "href": "https://docs.microsoft.com/en-us/azure/key-vault/keys/about-keys"
        }}>{`About Keys`}</a>{` page for more details.`}</li>
      <li parentName="ul">{`In `}<inlineCode parentName="li">{`kms`}</inlineCode>{`, an optional `}<inlineCode parentName="li">{`uri`}</inlineCode>{` property can be added to provide client credentials (eg. `}<inlineCode parentName="li">{`azurekms:client-id=fooo;client-secret=bar;tenant-id=9de53416-4431-4181-7a8b-23af3EXAMPLE`}</inlineCode>{`) instead of using the environment variables described above.`}</li>
    </ul>
    <h3>{`PKCS #11`}</h3>
    <Alert severity="info" mdxType="Alert">
  <div>
    <strong>This feature is not enabled by default.</strong><br />
    To enable support, you will need to build <code>step-ca</code> yourself, using CGO support.<br />
    See <a href="https://github.com/smallstep/certificates/blob/master/CONTRIBUTING.md#build-step-ca-using-cgo">Building From Source Using CGO</a>. 
  </div>
    </Alert>
    <p>{`A Hardware Security Module (HSM) is a specialized piece of hardware that is designed to generate and store private keys, and sign messages using those keys.
The private keys on an HSM cannot be exported from the device.
One can only run signing operations using the key.
This is an excellent way to protect private keys for a Certificate Authority,
which in normal operation simply needs to be able to sign Certificate Signing Requests.`}</p>
    <p><a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/PKCS_11"
      }}>{`Public-Key Cryptography Standards #11 (PKCS #11)`}</a>{` is the most common platform-independent API used to access HSM hardware.
It's supported by most HSM hardware, like Yubico's `}<a parentName="p" {...{
        "href": "https://www.yubico.com/product/yubihsm-2/"
      }}>{`YubiHSM2`}</a>{`, and the `}<a parentName="p" {...{
        "href": "https://shop.nitrokey.com/shop/product/nk-hsm-2-nitrokey-hsm-2-7"
      }}>{`Nitrokey HSM 2`}</a>{`.
There's also a software-based "HSM," `}<a parentName="p" {...{
        "href": "https://github.com/opendnssec/SoftHSMv2"
      }}>{`SoftHSMv2`}</a>{`, which offers a PKCS #11 interface without the hardware.
Finally, you can use PKCS#11 with a Trusted Platform Module (TPM) 2.0 chip on Linux. This requires the `}<a parentName="p" {...{
        "href": "https://github.com/tpm2-software/tpm2-pkcs11"
      }}>{`libtpm2_pkcs11.so`}</a>{` library.`}</p>
    <h4>{`0. Before you begin`}</h4>
    <p>{`Your HSM or TPM2 may need to be prepared before you can initialize a PKI on it.
Preparation steps depend on the device and are beyond the scope of this documentation.
For example,`}</p>
    <ul>
      <li parentName="ul">{`With YubiHSM2, you will need to create an authentication key with appropriate capabiliites.`}</li>
      <li parentName="ul">{`With TPM2, you will need to initialize the TPM and create a PKCS#11 token on it.`}</li>
    </ul>
    <p>{`You'll also want to consider how you will backup and restore your CA keys, for offline storage.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`step kms`}</inlineCode>{` plugin will create CA keys and sign certificates on your device.
You'll need to provide a PKCS #11 URI for accessing the device, using the `}<inlineCode parentName="p">{`--kms`}</inlineCode>{` flag.`}</p>
    <p>{`Here are some examples of PKCS #11 URIs for accessing various devices in Linux:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`HSM`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`URI format`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`YubiHSM2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`pkcs11:module-path=/usr/lib/x86_64-linux-gnu/pkcs11/yubihsm_pkcs11.so;token=YubiHSM`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`AWS CloudHSM`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`pkcs11:module-path=/opt/cloudhsm/lib/libcloudhsm_pkcs11.so;token=cavium?pin-value=$HSM_USER:$HSM_PASSWORD`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`SoftHSM`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`pkcs11:module-path=/usr/lib/softhsm/libsofthsm2.so;token=token1?pin-value=$HSM_PASSWORD`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`nCipher nShield`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`pkcs11:module-path=/opt/nfast/toolkits/pkcs11/libcknfast.so;token=rjk?pin-source=/etc/step-ca/hsm-pin.txt`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`TPM2 (via libtpm2_pkcs11)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`pkcs11:module-path=/usr/local/lib/libtpm2_pkcs11.so;token=step-ca?pin-value=$HSM_PASSWORD`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <p>{`You'll need to substitute `}<inlineCode parentName="p">{`$HSM_USER`}</inlineCode>{` and `}<inlineCode parentName="p">{`$HSM_PASSWORD`}</inlineCode>{` with your own values.`}</p>
    <p>{`In this URI,`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`module-path`}</inlineCode>{` points to your PKCS #11 `}<inlineCode parentName="li">{`.dll`}</inlineCode>{`, `}<inlineCode parentName="li">{`.so`}</inlineCode>{`, or `}<inlineCode parentName="li">{`.dylib`}</inlineCode>{` library file,`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`token`}</inlineCode>{` is the label (`}<inlineCode parentName="li">{`CKA_LABEL`}</inlineCode>{`) of the HSM you're using,`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`pin-value`}</inlineCode>{` contains hardcoded HSM credentials. It may be a PIN, username and password, password, or a filename. The YubiHSM2 is special in that the PIN value is the concatenation of the four-digit authorization key ID (eg. `}<inlineCode parentName="li">{`0001`}</inlineCode>{`) and the PIN.`}</li>
      <li parentName="ul">{`Or, `}<inlineCode parentName="li">{`pin-source`}</inlineCode>{` is a filename containing HSM credentials.`}</li>
    </ul>
    <h4>{`1. Create your PKI`}</h4>
    <p>{`Once you've constructed the right URI for accessing your device, use it in place of `}<inlineCode parentName="p">{`$PKCS_URI`}</inlineCode>{` in the commands below.`}</p>
    <p>{`First, please install the `}<a parentName="p" {...{
        "href": "https://github.com/smallstep/step-kms-plugin"
      }}><inlineCode parentName="a">{`step kms`}</inlineCode>{` plugin`}</a>{` before you begin. You'll need it to create your PKI.`}</p>
    <p>{`Next, let's ask the device to generate a private key for your root CA. Run:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ step kms create --json --kms "$PKCS_URI" "pkcs11:id=7331;object=root-ca"
`}</code></pre>
    <p>{`Once the key is generated, `}<inlineCode parentName="p">{`step`}</inlineCode>{` will output the key ID and the public key PEM:`}</p>
    <pre><code parentName="pre" {...{}}>{`{
  "name": "pkcs11:id=7331;object=root-ca",
  "publicKey": "-----BEGIN PUBLIC KEY-----\\nMFkwEwYHKoZIzj0CAQYIKoZIzj0DAQcDQgAEH2ls6h1y2jsXV+IeuhDVkb68zkMe\\nKPtI7L6vBIa5ThxOyFaZFnUrGWU6B+KQjProAntgKyNTqOnAh7Eyr3RmgQ==\\n-----END PUBLIC KEY-----\\n"
}
`}</code></pre>
    <p>{`You'll need this key name for the next step.`}</p>
    <Alert severity="info" mdxType="Alert">
  <div>
	We have taken care to provide sane defaults in `step kms create`, but you may wish to change the key type, size, protection level, or other options for your environment. See <Code mdxType="Code">step kms help create</Code> for details.
  </div>
    </Alert>
    <p>{`Now, let's sign a root CA certificate based on the the key you just created. Substitute the key name output from `}<inlineCode parentName="p">{`step kms create`}</inlineCode>{` here:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ step certificate create --profile root-ca \\
   --kms "$PKCS_URI"
   --key "pkcs11:id=7331;object=root-ca" \\
   "Smallstep Root CA" root_ca.crt
`}</code></pre>
    <p>{`Output:`}</p>
    <pre><code parentName="pre" {...{}}>{`Your certificate has been saved in root_ca.crt.
`}</code></pre>
    <p>{`Great. Next, we'll repeat the process for the Intermediate CA:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ step kms create --json --kms "$PKCS_URI" "pkcs11:id=7332;object=intermediate-ca"
$ step certificate create --profile intermediate-ca \\
   --kms "$PKCS_URI" \\
   --ca root_ca.crt \\
   --ca-key "pkcs11:id=7331;object=root-ca" \\
   --key "pkcs11:id=7332;object=intermediate-ca" \\
   "Smallstep Intermediate CA" intermediate_ca.crt
`}</code></pre>
    <p>{`Here, the `}<inlineCode parentName="p">{`--ca-key`}</inlineCode>{` is the root CA key id; the `}<inlineCode parentName="p">{`--key`}</inlineCode>{` is the intermediate CA key id.`}</p>
    <p>{`Output:`}</p>
    <pre><code parentName="pre" {...{}}>{`Your certificate has been saved in intermediate_ca.crt.
`}</code></pre>
    <p>{`Now you should have both `}<inlineCode parentName="p">{`root_ca.crt`}</inlineCode>{` and `}<inlineCode parentName="p">{`intermediate_ca.crt`}</inlineCode>{` certificate PEM files.
You'll need these files for your CA configuration, below.`}</p>
    <p>{`If you want to run an SSH CA, you'll also need to create SSH CA key pairs:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ step kms create --json --kms "$PKCS_URI" "pkcs11:id=7333;object=ssh-host-ca"
$ step kms create --json --kms "$PKCS_URI" "pkcs11:id=7334;object=ssh-user-ca"
`}</code></pre>
    <p>{`Hold onto the key IDs from these commands; you'll need them below.`}</p>
    <h4>{`Configuring `}<inlineCode parentName="h4">{`step-ca`}</inlineCode>{` to use PKCS #11`}</h4>
    <p>{`One you've created your PKI on the HSM using `}<inlineCode parentName="p">{`step-kms-plugin`}</inlineCode>{`, you'll need to configure `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` to use the HSM.`}</p>
    <p>{`To configure your certificate authority, add the `}<inlineCode parentName="p">{`kms`}</inlineCode>{` object to `}<inlineCode parentName="p">{`ca.json`}</inlineCode>{` and replace the `}<inlineCode parentName="p">{`key`}</inlineCode>{` property with the object ID of your intermediate CA key:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`{
    "root": "/etc/step-ca/certs/root_ca.crt",
    "crt": "/etc/step-ca/certs/intermediate_ca.crt",
    "key": "pkcs11:id=7332;object=intermediate-ca",
    "kms": {
        "type": "pkcs11",
        "uri": "$PKCS_URI"
    }
}
`}</code></pre>
    <p>{`Finally, copy the `}<inlineCode parentName="p">{`root_ca.crt`}</inlineCode>{` and `}<inlineCode parentName="p">{`intermediate_ca.crt`}</inlineCode>{` files into the `}<inlineCode parentName="p">{`root`}</inlineCode>{` and `}<inlineCode parentName="p">{`crt`}</inlineCode>{` locations:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ cp root_ca.crt intermediate_ca.crt $(step path)/certs
`}</code></pre>
    <p>{`Your X.509 CA is ready.`}</p>
    <p>{`To configure an SSH CA, replace the SSH key locations with the SSH CA keys you created above:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`{
    "ssh": {
        "hostKey": "pkcs11:id=7333;object=ssh-host-ca",
        "userKey": "pkcs11:id=7334;object=ssh-user-ca"
    }
}
`}</code></pre>
    <p>{`When you start `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{`, you will see your X.509 root fingerprint, and the SSH user and host CA keys in SSH key format:`}</p>
    <pre><code parentName="pre" {...{}}>{`2022/09/20 16:28:45 The primary server URL is https://localhost:443
2022/09/20 16:28:45 Root certificates are available at https://localhost:443/roots.pem
2022/09/20 16:28:45 X.509 Root Fingerprint: b061dfca1013c074244b0f376e5be70b6eb0bd7f21d5438aa3af71fe62b0acf5
2022/09/20 16:28:45 SSH Host CA Key: ecdsa-sha2-nistp256 AAAAE2VjZHNhLXNoYTItbmlzdHAyNTYAAAAIbmlzdHAyNTYAAABBBIwmL7aDOJId/9UOVJGhVux6Rlvea+q2017aLsfze+/EwGQ5BdZ4k2Qh+5VekebBKZYLNO0LkSf9bZb4o9GSxIs=
2022/09/20 16:28:45 SSH User CA Key: ecdsa-sha2-nistp256 AAAAE2VjZHNhLXNoYTItbmlzdHAyNTYAAAAIbmlzdHAyNTYAAABBBFE0VY9eLxkoHrXoWk5VxeOQTUt53U5xIo89pfsgYHh450cdE4c3mYw5YeOueESyu/lFUHfJoNS6twVR1wuCOdc=
2022/09/20 16:28:45 Serving HTTPS on :443 ...
`}</code></pre>
    <h3>{`YubiKey PIV`}</h3>
    <Alert severity="info" mdxType="Alert">
  <div>
    <strong>This feature is not enabled in <code>step-ca</code> by default</strong><br />
    To enable support, you will need to build <code>step-ca</code> yourself, using CGO support. See <a href="https://github.com/smallstep/certificates/blob/master/CONTRIBUTING.md#build-step-ca-using-cgo">Building From Source Using CGO</a>. 
  </div>
    </Alert>
    <p>{`You can leverage a hardware `}<a parentName="p" {...{
        "href": "https://www.yubico.com/"
      }}>{`YubiKey`}</a>{`—and the `}<a parentName="p" {...{
        "href": "https://www.yubico.com/authentication-standards/smart-card/"
      }}>{`YubiKey PIV application`}</a>{`—to store your CA keys and sign TLS and SSH certificates.`}</p>
    <h4>{`Prerequisites and Caveats`}</h4>
    <ul>
      <li parentName="ul">{`To enable YubiKey support in `}<inlineCode parentName="li">{`step-ca`}</inlineCode>{`, you must follow our `}<a parentName="li" {...{
          "href": "https://github.com/smallstep/certificates/blob/master/CONTRIBUTING.md#build-step-ca-using-cgo"
        }}>{`Instructions for building from source using CGO`}</a></li>
      <li parentName="ul">{`You will need a YubiKey 5 series device that `}<a parentName="li" {...{
          "href": "https://www.yubico.com/store/compare/"
        }}>{`supports the PIV application`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developers.yubico.com/PIV/Introduction/Certificate_slots.html"
        }}>{`Certificate slots`}</a>{` 9a, 9c, 9d, 9e, and 82-95 are supported`}</li>
      <li parentName="ul">{`You can use the YubiKey for X.509 and SSH CAs`}</li>
    </ul>
    <p>{`Please install the `}<a parentName="p" {...{
        "href": "https://github.com/smallstep/step-kms-plugin"
      }}><inlineCode parentName="a">{`step kms`}</inlineCode>{` plugin`}</a>{` before you begin. You'll need it to create your PKI.`}</p>
    <p>{`Now, insert your YubiKey.
Let's generate a private key for your root CA in slot 82 on the YubiKey.
Run:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ step kms create --json 'yubikey:slot-id=82'
`}</code></pre>
    <p>{`Once the key is generated, `}<inlineCode parentName="p">{`step`}</inlineCode>{` will output the key name and public key PEM:`}</p>
    <pre><code parentName="pre" {...{}}>{`{
  "name": "yubikey:slot-id=82",
  "publicKey": "-----BEGIN PUBLIC KEY-----\\nMFkwEwYHKoZIzj0CAQYIKoZIzj0DAQcDQgAED3T/7q+p+6239Ri35TBVoChM6VNq\\n1buLfql1acRl7F2qf/L96x9XHY5GHoqYNCAm/ocL9hTl8ytWJao+JSNE+Q==\\n-----END PUBLIC KEY-----\\n"
}
`}</code></pre>
    <Alert severity="info" mdxType="Alert">
  <div>
	We have taken care to provide sane defaults in `step kms create`, but you may wish to change the key type, size, or other options for your environment. See <Code mdxType="Code">step kms help create</Code> for details.
  </div>
    </Alert>
    <p>{`Now, let's sign a root CA certificate based on the the key you just created. Substitute the key name output from `}<inlineCode parentName="p">{`step kms create`}</inlineCode>{` here:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ step certificate create --profile root-ca \\
   --kms 'yubikey:pin-value=123456' \\
   --key 'yubikey:slot-id=82' \\
   "Smallstep Root CA" root_ca.crt
`}</code></pre>
    <p>{`Here we're using the default PIN code of 123456 to access the YubiKey.`}</p>
    <p>{`Output:`}</p>
    <pre><code parentName="pre" {...{}}>{`Your certificate has been saved in root_ca.crt.
`}</code></pre>
    <p>{`Great. Next, we'll repeat the process for the Intermediate CA:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ step kms create --json 'yubikey:slot-id=83'
$ step certificate create --profile intermediate-ca \\
   --kms 'yubikey:pin-value=123456' \\
   --ca root_ca.crt \\
   --ca-key 'yubikey:slot-id=82' \\
   --key 'yubikey:slot-id=83' \\
   "Smallstep Intermediate CA" intermediate_ca.crt
`}</code></pre>
    <p>{`Here, the `}<inlineCode parentName="p">{`--ca-key`}</inlineCode>{` is the root CA key id; the `}<inlineCode parentName="p">{`--key`}</inlineCode>{` is the intermediate CA key id.`}</p>
    <p>{`Output:`}</p>
    <pre><code parentName="pre" {...{}}>{`Your certificate has been saved in intermediate_ca.crt.
`}</code></pre>
    <p>{`Now you should have both `}<inlineCode parentName="p">{`root_ca.crt`}</inlineCode>{` and `}<inlineCode parentName="p">{`intermediate_ca.crt`}</inlineCode>{` certificate PEM files.
You'll need these files for your CA configuration, below.`}</p>
    <p>{`for safekeeping, you may wish to import the certificates into the YubiKey. To do this, you'll need Yubico's `}<a parentName="p" {...{
        "href": "https://developers.yubico.com/yubikey-manager/"
      }}><inlineCode parentName="a">{`ykman`}</inlineCode>{` CLI utility`}</a>{`. Run:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ ykman piv certificates import 82 root_ca.crt
$ ykman piv certificates import 83 intermediate_ca.crt
`}</code></pre>
    <p>{`(While `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` won't use these copies of the certificates, you can always use `}<inlineCode parentName="p">{`ykman piv certificates export`}</inlineCode>{` to download the certificates later.)`}</p>
    <p>{`Next, if you want to run an SSH CA, you'll also need to create two SSH CA keys:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ step kms create --json 'yubikey:slot-id=84'
$ step kms create --json 'yubikey:slot-id=85'
`}</code></pre>
    <p>{`Finally, to enable your CA in `}<inlineCode parentName="p">{`ca.json`}</inlineCode>{`, point the `}<inlineCode parentName="p">{`root`}</inlineCode>{` and `}<inlineCode parentName="p">{`crt`}</inlineCode>{` options to the generated certificates, replace the `}<inlineCode parentName="p">{`key`}</inlineCode>{` option with the YubiKey URI generated in the previous part, and configure the `}<inlineCode parentName="p">{`kms`}</inlineCode>{` option with the appropriate `}<inlineCode parentName="p">{`type`}</inlineCode>{` and `}<inlineCode parentName="p">{`pin`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`{
    "root": "/etc/step-ca/certs/root_ca.crt",
    "crt": "/etc/step-ca/certs/intermediate_ca.crt",
    "key": "yubikey:slot-id=83",
    "kms": {
        "type": "yubikey",
        "uri": "yubikey:management-key=01020304...?pin-value=123456"
    }
}
`}</code></pre>
    <p>{`Finally, copy the `}<inlineCode parentName="p">{`root_ca.crt`}</inlineCode>{` and `}<inlineCode parentName="p">{`intermediate_ca.crt`}</inlineCode>{` files into the `}<inlineCode parentName="p">{`root`}</inlineCode>{` and `}<inlineCode parentName="p">{`crt`}</inlineCode>{` locations:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ cp root_ca.crt intermediate_ca.crt $(step path)/certs
`}</code></pre>
    <p>{`Your X.509 CA is ready.`}</p>
    <p>{`To configure an SSH CA, replace the SSH key locations with the SSH CA keys you created in AWS KMS:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`{
    "ssh": {
        "hostKey": "yubikey:slot-id=84",
        "userKey": "yubikey:slot-id=85"
    }
}
`}</code></pre>
    <p>{`When you start `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{`, you will see your X.509 root fingerprint, and the SSH host and user CA keys in SSH key format:`}</p>
    <pre><code parentName="pre" {...{}}>{`2022/09/20 16:28:45 The primary server URL is https://localhost:443
2022/09/20 16:28:45 Root certificates are available at https://localhost:443/roots.pem
2022/09/20 16:28:45 X.509 Root Fingerprint: b061dfca1013c074244b0f376e5be70b6eb0bd7f21d5438aa3af71fe62b0acf5
2022/09/20 16:28:45 SSH Host CA Key: ecdsa-sha2-nistp256 AAAAE2VjZHNhLXNoYTItbmlzdHAyNTYAAAAIbmlzdHAyNTYAAABBBIwmL7aDOJId/9UOVJGhVux6Rlvea+q2017aLsfze+/EwGQ5BdZ4k2Qh+5VekebBKZYLNO0LkSf9bZb4o9GSxIs=
2022/09/20 16:28:45 SSH User CA Key: ecdsa-sha2-nistp256 AAAAE2VjZHNhLXNoYTItbmlzdHAyNTYAAAAIbmlzdHAyNTYAAABBBFE0VY9eLxkoHrXoWk5VxeOQTUt53U5xIo89pfsgYHh450cdE4c3mYw5YeOueESyu/lFUHfJoNS6twVR1wuCOdc=
2022/09/20 16:28:45 Serving HTTPS on :443 ...
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      